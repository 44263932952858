import { ReactNode } from 'react';
import { Tooltip as AntDTooltip } from 'antd';
import { martsColors } from '../../../types/colors';
import { Falsy } from '@/types/index';

interface TooltipProps {
  children: ReactNode;
  tooltip?: string | Falsy;
}
// https://ant.design/components/tooltip
export const Tooltip = ({ children, tooltip }: TooltipProps) => {
  if (!tooltip) return <>{children}</>;
  return (
    <AntDTooltip
      title={tooltip}
      arrow={false}
      style={{
        background: martsColors.black,
        color: martsColors.white,
        fontWeight: '500',
        lineHeight: '16px',
        letterSpacing: '-0.12px',
        padding: '4px 8px 4px 8px',
        borderRadius: '4px',
      }}
      overlayStyle={{ maxWidth: '500px' }}
      align={{
        offset: [0, -15],
      }}
    >
      {children}
    </AntDTooltip>
  );
};
