import { useEffect } from 'react';

interface Props {
  selector: string;
  className?: string;
  onClick?: (evt: Event) => void;
}

const QuerySelector = ({ selector, className, onClick }: Props) => {
  useEffect(() => {
    const element = document.querySelector(selector);

    if (element) {
      if (typeof className !== 'undefined') {
        element.className = className;
      }

      if (typeof onClick !== 'undefined') {
        element.addEventListener('click', onClick);
      }

      return () => {
        if (typeof onClick !== 'undefined') {
          element.removeEventListener('click', onClick);
        }
      };
    }

    return () => {};
  }, [className, onClick, selector]);
  return null;
};

export default QuerySelector;
