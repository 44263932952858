const getNavigatorLanguage = () =>
  navigator.languages?.[0] || navigator.language || 'en';

const formatPrice = (price: number) => {
  return new Intl.NumberFormat(getNavigatorLanguage(), {
    style: 'currency',
    currency: 'DKK',
    currencyDisplay: 'symbol',
  }).format(price / 100);
};

export default formatPrice;
