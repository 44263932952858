import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import Icon from '../../Theme/Icon/Icon';

interface Props {
  isOpen: boolean;
  title: string;
  children: ReactNode;
  buttons?: ReactNode;
  beforeButtons?: ReactNode;
  onRequestClose: () => void;
  className?: string;
}

export default function Modal({
  isOpen,
  title,
  children,
  buttons,
  beforeButtons,
  onRequestClose,
  className,
}: Props) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onRequestClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium">
                            {title}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="absolute right-5 top-6 rounded-full hover:bg-bg w-8 h-8 cursor-pointer grid place-items-center"
                              onClick={onRequestClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <Icon className="w-[16px] z-1" name="xClose" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`relative mt-8 flex-1 px-4 sm:px-6 ${className}`}
                      >
                        {children}
                        {!!beforeButtons && (
                          <div className="flex">{beforeButtons}</div>
                        )}
                      </div>
                    </div>
                    {!!buttons && (
                      <div className="flex flex-shrink-0 justify-end px-4 py-4 border-border">
                        {buttons}
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
