import {
  Address,
  Company,
  DeliverySetting,
  EntityData,
  EntityType,
  Request,
  User,
} from '@/types/index';
import { RootStore } from '@/store/index';
import { UseFormGetValues } from 'react-hook-form';

export default class Requests {
  public entityType = EntityType.REQUEST;

  public myApproverRequestIds: Request['id'][] = [];

  public archiveRequestIds: Request['id'][] = [];

  public allOrderIds: Request['id'][] = [];

  public data: EntityData<Request | null> = {};

  public getOnboardingValues: UseFormGetValues<
    Address & {
      addressType: DeliverySetting;
    }
  > | null = null;

  public isOnboardingRequested = false;

  public id: Request['id'] | null = null;

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public submitRequest = async (body: {
    assigneeId: User['id'];
    address: Address & { addressType: DeliverySetting };
  }) => {
    await this.#store.api('requests', {
      method: 'POST',
      body,
    });
  };

  public submitOnboardingRequest = async (
    onboardingId: string,
    body: Address & { addressType: DeliverySetting },
  ) => {
    await this.#store.api(`onboarding/${onboardingId}/request`, {
      method: 'POST',
      body,
      successMessage: 'Successfully requested the order',
      noAuth: true,
    });

    this.isOnboardingRequested = true;
  };

  public loadMyApproverRequests = async () => {
    this.myApproverRequestIds = await this.#store.api(
      'requests/pending-my-approval',
      { method: 'GET' },
    );
  };

  public loadArchivedRequests = async () => {
    this.archiveRequestIds = await this.#store.api('requests/archived', {
      method: 'GET',
    });
  };

  public getAllOrders = async (companyId?: Company['id']) => {
    const endpoint = companyId
      ? `requests/all-orders?companyId=${companyId}`
      : 'requests/all-orders';
    this.allOrderIds = await this.#store.api(endpoint, {
      method: 'GET',
    });
  };

  public loadSingleRequest = async (id: Request['id']) => {
    this.id = await this.#store.api(`requests/${id}`, { method: 'GET' });
  };

  public loadSingleOrder = async (id: Request['id']) => {
    this.id = await this.#store.api(`requests/order/${id}`, { method: 'GET' });
  };

  public approveRequest = async (id: Request['id']) => {
    await this.#store.api(`requests/${id}/approve`, {
      method: 'POST',
      successMessage: 'Successfully approved the request',
    });
  };

  public rejectRequest = async (id: Request['id']) => {
    await this.#store.api(`requests/${id}/reject`, {
      method: 'POST',
      successMessage: 'Successfully rejected the order',
    });
  };

  public getLatestShipmentDate = (requestId: string) => {
    if (!this) return null;
    const request = this.data[requestId];
    if (!request) {
      return null;
    }

    const requestItems = request.requestItems.map(
      (id) => this.#store.requestItems.data[id],
    );
    const shipmentDates = requestItems
      .filter((ri) => ri?.shippedAt)
      .map((ri) => ri?.shippedAt)
      .filter(Boolean)
      .sort(
        (a, b) =>
          new Date(b as string).getTime() - new Date(a as string).getTime(),
      );

    return shipmentDates[0] || null;
  };
}
