import { ReactNode } from 'react';
import Icon from '../../Theme/Icon/Icon';
import { toast } from 'react-toastify';

interface Props {
  type: 'success' | 'error';
  children: ReactNode;
  closeToast?: () => void;
}

export const Toast = ({ type, children, closeToast }: Props) => (
  <div className="flex items-center p-3">
    {type === 'success' && <Icon name="check" className="w-[16px]" />}
    {type === 'error' && (
      <Icon name="infoCircle" className="w-[16px] text-negative" />
    )}
    <div className="flex-1 pl-4">{children}</div>
    {!!closeToast && <Icon name="xClose" className="w-[16px]" />}
  </div>
);

export const successToast = (message: string) => {
  toast.success(
    ({ closeToast }) => (
      <Toast type="success" closeToast={closeToast}>
        {message}
      </Toast>
    ),
    {
      position: toast.POSITION.TOP_CENTER,
    },
  );
};

export const errorToast = (message: string) => {
  toast.error(
    ({ closeToast }) => (
      <Toast type="error" closeToast={closeToast}>
        {message}
      </Toast>
    ),
    {
      position: toast.POSITION.TOP_CENTER,
    },
  );
};
