import type { RootStore } from '.';
import { EntityData, EntityType, ProductOptionValue } from '@/types/index';

export default class ProductOptionValues {
  public entityType = EntityType.MEDUSA_PRODUCT_OPTION_VALUE;

  public ids: ProductOptionValue['id'][] = [];

  public data: EntityData<ProductOptionValue> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }
}
