export const martsColors = {
  text: '#151516',
  'text-light': '#757576',
  bg: '#F5F5F5',
  'bg-light': '#FBFBFC',
  border: '#E9E9EE',
  'border-light': '#F2F2F2',
  positive: '#389452', // Will be removed eventually
  'positive-dark': '#2A5F2F',
  'positive-strong': '#408E47',
  'positive-light': '#EFF8EF',
  negative: '#D97D61', // Will be removed eventually
  'negative-dark': '#873223',
  'negative-strong': '#C74D38',
  'negative-light': '#FFF2EF',
  'highlight-dark': '#32556F',
  'highlight-strong': '#3D81B0',
  'highlight-light': '#EDF7FF',
  white: '#FFFFFF',
  black: '#151516',
};

export type MartsColorClassNames = keyof typeof martsColors;
