import { memo, ReactNode } from 'react';
import classNames from 'classnames';
import Spinner from '../../Loading/Spinner/Spinner';
import { Tooltip } from '../../Components/Tooltip/Tooltip';

interface Props {
  isLoading?: boolean;
  color?: 'primary' | 'secondary' | 'transparent' | 'negative';
  className?: string;
  size?: 'sm' | 'md';
  children: ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit';
  form?: string;
  onClick?: (e?: React.MouseEvent) => void | Promise<void>;
  tooltip?: string;
}

const Button = ({
  isLoading,
  color = 'secondary',
  className,
  size = 'md',
  children,
  disabled,
  type = 'button',
  form,
  onClick,
  tooltip,
}: Props) => {
  const spinnerTheme = {
    secondary: 'light' as const,
    transparent: 'light' as const,
    negative: 'light' as const,
    primary: 'dark' as const,
  }[color];

  return (
    <button
      type={type}
      disabled={disabled}
      form={form}
      className={classNames({
        [className as string]: !!className,
        'transition-all rounded-l-full rounded-r-full text-base-heavy relative whitespace-nowrap':
          true,
        'cursor-pointer': !disabled && !isLoading,
        'opacity-50 cursor-not-allowed': disabled,
        'cursor-progress': isLoading,
        'px-4 py-2.5': size === 'md' && color !== 'secondary',
        'px-3.5 py-1.5': size === 'sm' && color !== 'secondary',
        'px-4 py-[9px]': size === 'md' && color === 'secondary',
        'px-3.5 py-[5px]': size === 'sm' && color === 'secondary',
        'border border-border bg-white jsx-secondary': color === 'secondary',
        'bg-black text-white': color === 'primary',
        'bg-negative-strong text-white': color === 'negative',
        'jsx-transparent': color === 'transparent',
      })}
      onClick={!isLoading ? onClick : undefined}
    >
      <style jsx>{`
        .jsx-transparent:hover:not(:disabled),
        .jsx-secondary:hover:not(:disabled) {
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.23);
        }
      `}</style>
      <div className={isLoading ? 'opacity-0' : undefined}>
        <Tooltip tooltip={tooltip}>{children}</Tooltip>
      </div>
      {isLoading && (
        <div className="absolute w-full h-full left-0 top-0 flex items-center justify-center">
          <Spinner className="!w-6 !h-6" theme={spinnerTheme} />
        </div>
      )}
    </button>
  );
};

export default memo(Button);
