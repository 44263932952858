import type { RootStore } from '.';
import { EntityData, EntityType, UserSelection } from '@/types/index';

export default class UserSelections {
  public entityType = EntityType.USER_SELECTION;

  public data: EntityData<UserSelection> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }
}
