import type { RootStore } from '.';
import { Company, EntityData, EntityType, Team, User } from '@/types/index';
import useMap from '@/lib/useMap';

export default class Teams {
  public entityType = EntityType.TEAM;

  public teamIds: Team['id'][] = [];

  public myTeams: Team['id'][] = [];

  public data: EntityData<Team> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public teams = (writeAccess: boolean = false) => {
    const teams = useMap(this.data, this.teamIds);

    if (
      this.#store.users.isOnlyServiceAgent &&
      writeAccess &&
      teams.length !== 0
    ) {
      return teams.filter(
        (team) => team.companyId !== this.#store.companies.myCompany,
      );
    }

    return teams;
  };

  public teamsByCompany = (companyId: Company['id'] | undefined) => {
    const allTeams = this.teams();
    if (!companyId) return allTeams;
    return this.teams().filter((team) => team.companyId === companyId);
  };

  public getColorByName = (teamName: string, alpha?: number) => {
    const hue = [...teamName].reduce(
      (acc, letter) => (acc + letter.charCodeAt(0)) % 360,
      0,
    );

    return `hsla(${hue}, 45%, 38%, ${alpha ?? 1})`;
  };

  public createTeam = async (
    body: Omit<Team, 'entityType' | 'id' | 'createdAt' | 'updatedAt'>,
  ) => {
    const newTeamId = await this.#store.api('teams', {
      method: 'POST',
      body,
      successMessage: 'Successfully created a new catalogue',
    });
    if (typeof newTeamId === 'string') this.teamIds.push(newTeamId);
  };

  public loadTeams = async (companyId?: Company['id']) => {
    const endpoint = companyId ? `teams?companyId=${companyId}` : 'teams';
    this.teamIds = await this.#store.api(endpoint, { method: 'GET' });
  };

  public companyTeams = (companyId?: Company['id'] | null) => {
    const teams = Object.values(this.data).filter(
      (team) => typeof team,
    ) as Team[];
    if (!companyId) return teams;
    return teams.filter((team) => team.companyId === companyId) as Team[];
  };

  public loadSingleTeam = (id: Team['id']) => {
    return this.#store.api(`teams/${id}`, { method: 'GET' });
  };

  public updateTeam = async (id: Team['id'], body: Partial<Team>) => {
    await this.#store.api(`teams/${id}`, {
      method: 'PUT',
      body,
      successMessage: 'Successfully updated the catalogue',
    });
  };

  public deleteTeam = async (id: Team['id']) => {
    await this.#store.api(`teams/${id}`, {
      method: 'DELETE',
      successMessage: 'Successfully deleted the catalogue',
    });
  };

  public assignUsers = async (id: Team['id'], userIds: User['id'][]) => {
    return this.#store.api(`teams/assign-users/${id}`, {
      method: 'POST',
      body: { userIds },
      successMessage: 'Successfully assigned the user to the team',
    });
  };
}
