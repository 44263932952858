import type { RootStore } from '.';

export const ROOT = (store: RootStore) => store;

export const USERS = (store: RootStore) => store.users;
USERS.DATA = (store: RootStore) => store.users.data;

export const USER_SELECTIONS = (store: RootStore) => store.userSelections;
USER_SELECTIONS.DATA = (store: RootStore) => store.userSelections.data;

export const USER_SELECTION_RULES = (store: RootStore) =>
  store.userSelectionRules;
USER_SELECTION_RULES.DATA = (store: RootStore) => store.userSelectionRules.data;

export const ROLES = (store: RootStore) => store.roles;
ROLES.DATA = (store: RootStore) => store.roles.data;

export const COMPANIES = (store: RootStore) => store.companies;
COMPANIES.DATA = (store: RootStore) => store.companies.data;

export const DIRECTORY_SYNCS = (store: RootStore) => store.directorySyncs;
DIRECTORY_SYNCS.DATA = (store: RootStore) => store.directorySyncs.data;

export const OFFICES = (store: RootStore) => store.offices;
OFFICES.DATA = (store: RootStore) => store.offices.data;

export const REQUESTS = (store: RootStore) => store.requests;
REQUESTS.DATA = (store: RootStore) => store.requests.data;

export const REQUEST_ITEMS = (store: RootStore) => store.requestItems;
REQUEST_ITEMS.DATA = (store: RootStore) => store.requestItems.data;

export const TEAMS = (store: RootStore) => store.teams;
TEAMS.DATA = (store: RootStore) => store.teams.data;

export const EQUIPMENT_ITEMS = (store: RootStore) => store.equipmentItems;
EQUIPMENT_ITEMS.DATA = (store: RootStore) => store.equipmentItems.data;

export const EQUIPMENT_LOGS = (store: RootStore) => store.equipmentLogs;
EQUIPMENT_LOGS.DATA = (store: RootStore) => store.equipmentLogs.data;

export const EQUIPMENT_DOCUMENTS = (store: RootStore) =>
  store.equipmentDocuments;
EQUIPMENT_DOCUMENTS.DATA = (store: RootStore) => store.equipmentDocuments.data;

export const COLLECTIONS = (store: RootStore) => store.collections;
COLLECTIONS.DATA = (store: RootStore) => store.collections.data;

export const PRODUCTS = (store: RootStore) => store.products;
PRODUCTS.DATA = (store: RootStore) => store.products.data;

export const LINE_ITEMS = (store: RootStore) => store.lineItems;
LINE_ITEMS.DATA = (store: RootStore) => store.lineItems.data;

export const PRODUCT_TYPES = (store: RootStore) => store.productTypes;
PRODUCT_TYPES.DATA = (store: RootStore) => store.productTypes.data;

export const PRODUCT_VARIANTS = (store: RootStore) => store.productVariants;
PRODUCT_VARIANTS.DATA = (store: RootStore) => store.productVariants.data;

export const MEDUSA_ADDRESS = (store: RootStore) => store.medusaAddress;
MEDUSA_ADDRESS.DATA = (store: RootStore) => store.medusaAddress.data;

export const MEDUSA_IMAGES = (store: RootStore) => store.medusaImages;
MEDUSA_IMAGES.DATA = (store: RootStore) => store.medusaImages.data;

export const MONEY_AMOUNTS = (store: RootStore) => store.moneyAmounts;
MONEY_AMOUNTS.DATA = (store: RootStore) => store.moneyAmounts.data;

export const MARGINS = (store: RootStore) => store.margins;
MARGINS.DATA = (store: RootStore) => store.margins.data;

export const PRODUCT_OPTIONS = (store: RootStore) => store.productOptions;
PRODUCT_OPTIONS.DATA = (store: RootStore) => store.productOptions.data;

export const PRODUCT_OPTION_VALUES = (store: RootStore) =>
  store.productOptionValues;
PRODUCT_OPTION_VALUES.DATA = (store: RootStore) =>
  store.productOptionValues.data;

export const CARTS = (store: RootStore) => store.carts;
CARTS.DATA = (store: RootStore) => store.carts.data;

export const MEDUSA_ORDERS = (store: RootStore) => store.medusaOrders;
MEDUSA_ORDERS.DATA = (store: RootStore) => store.medusaOrders.data;

export const MEDUSA_FULFILLMENTS = (store: RootStore) =>
  store.medusaFulfillments;
MEDUSA_FULFILLMENTS.DATA = (store: RootStore) => store.medusaFulfillments.data;

export const MEDUSA_TRACKING_LINKS = (store: RootStore) =>
  store.medusaTrackingLinks;
MEDUSA_TRACKING_LINKS.DATA = (store: RootStore) =>
  store.medusaTrackingLinks.data;

export const NEWS = (store: RootStore) => store.news;
NEWS.DATA = (store: RootStore) => store.news.data;
