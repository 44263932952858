import qs from 'qs';
import type { RootStore } from '.';
import {
  EntityData,
  EntityType,
  EquipmentDocument,
  EquipmentItem,
  Office,
} from '@/types/index';

export default class EquipmentDocuments {
  public entityType = EntityType.EQUIPMENT_DOCUMENT;

  public data: EntityData<EquipmentDocument> = {};

  public myOffice: Office['id'] | null = null;

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public createEquipmentDocument = async (
    equipmentItemId: EquipmentItem['id'],
    files: File[],
  ) => {
    const body = new FormData();
    for (const file of files) {
      body.append('files[]', file);
    }
    await this.#store.api(
      `equipment-documents?${qs.stringify({ equipmentItemId })}`,
      {
        method: 'POST',
        body,
        isFormData: true,
        successMessage:
          files.length > 1
            ? 'Successfully created new documents'
            : 'Successfully created a new document',
      },
    );
  };

  public loadEquipmentDocuments = async (
    equipmentItemId: EquipmentItem['id'],
  ) => {
    await this.#store.api(
      `equipment-documents?${qs.stringify({ equipmentItemId })}`,
      { method: 'GET' },
    );
  };

  public deleteEquipmentDocument = async (id: EquipmentItem['id']) => {
    await this.#store.api(`equipment-documents/${id}`, {
      method: 'DELETE',
      successMessage: 'Successfully deleted the document',
    });
  };
}
