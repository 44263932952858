import qs from 'qs';
import type { RootStore } from '.';
import {
  Cart,
  Company,
  EntityData,
  EntityType,
  Product,
  ProductVariant,
  StoreListResponse,
  Team,
} from '@/types/index';
import formatPrice from '@/lib/formatPrice';

export default class Products {
  public entityType = EntityType.MEDUSA_PRODUCT;

  public loadedProductIds: Product['id'][] = [];

  public totalCount = 0;

  public canLoadMore = true;

  public data: EntityData<Product> = {};

  public hasPersonalStoreItems = true;

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public loadProducts = async ({
    companyId,
    reset = true,
    storeType = 'marts',
    q,
    productType,
    collection,
    minPrice,
    maxPrice,
    inStock = false,
    inCatalogue = false,
    limit = 20,
    sort,
    ids,
    useExactIds,
  }: {
    companyId: Company['id'];
    reset?: boolean;
    storeType?: 'marts' | 'personal';
    useExactIds?: boolean;
    q?: string;
    productType?: string;
    collection?: string;
    minPrice?: string;
    maxPrice?: string;
    inStock?: boolean;
    inCatalogue?: boolean;
    limit?: number;
    sort?: 'price_dk:asc' | 'price_dk:desc' | 'stock:asc' | 'stock:desc';
    ids?: string[];
  }) => {
    if (reset) {
      this.loadedProductIds = [];
    }

    const offset = this.loadedProductIds.length;
    const response = await this.#store.api<
      StoreListResponse<{ products: Product['id'][] }>
    >(
      `products?${qs.stringify({
        companyId,
        storeType,
        limit,
        offset,
        q,
        productType,
        collection,
        minPrice,
        maxPrice,
        inStock,
        inCatalogue,
        sort,
        ids,
        useExactIds,
      })}`,
      { method: 'GET' },
    );
    this.loadedProductIds = [...this.loadedProductIds, ...response.products];
    this.totalCount = response.count;
    this.canLoadMore = this.loadedProductIds.length < this.totalCount;

    if (storeType === 'personal' && !q && !productType && !collection) {
      this.hasPersonalStoreItems = !!response.count;
    }
  };

  public loadSingleProduct = async (id: Product['id']) => {
    await this.#store.api<Product>(`products/${id}`, { method: 'GET' });
  };

  public approveTeams = async (
    id: Product['id'],
    teamIds?: Team['id'][],
    unassignTeamIds?: Team['id'][],
  ) => {
    return this.#store.api(`products/${id}/approve-teams`, {
      method: 'POST',
      body: { teamIds, unassignTeamIds },
      successMessage: 'Successfully updated team catalogue',
    });
  };

  /*
  Gets a product variant based on price, stock and backorder status.
  If you need the variant for a specific cart, pass the cartId as second argument.
  This could be the case during approval or looking at an order.
   */
  public getVariant = (productId: Product['id'], cartId?: Cart['id']) => {
    const variantId = this.#store.carts.getVariantIdIfProductIdInCart(
      productId,
      cartId,
    );
    let variant: ProductVariant | undefined;
    if (variantId) {
      variant = this.#store.productVariants.data[variantId];
    }
    if (!variantId) {
      const product = this.#store.products.data[productId];
      if (!product || !product.variants) {
        return null;
      }
      const productVariants = product.variants.map((variantId) => {
        return this.#store.productVariants.data[variantId];
      });

      let cheapestVariantInStock: ProductVariant | undefined;
      let cheapestVariantInStockDkk: number | undefined;
      let cheapestVariantWithBackorder: ProductVariant | undefined;
      let cheapestVariantWithBackorderDkk: number | undefined;
      let earliestVariantRestock: ProductVariant | undefined;
      let earliestVariantRestockDate: Date | undefined;
      let cheapestVariantOutOfStock: ProductVariant | undefined;
      let cheapestVariantOutOfStockDkk: number | undefined;

      productVariants
        .filter((variant) => !!variant.prices)
        .forEach((variant) => {
          const variantPriceDk = variant.prices.reduce((acc2, priceId) => {
            const p =
              this.#store.moneyAmounts.data[priceId as unknown as string];
            return (p.currency_code === 'dkk' && p.amount) || acc2;
          }, -1);
          const variantRestockDate = this.#store.productVariants.getRestockDate(
            variant.id,
          );
          if (variantPriceDk > -1) {
            if (variant.inventory_quantity > 0) {
              if (
                (cheapestVariantInStock === undefined &&
                  cheapestVariantInStockDkk === undefined) ||
                (cheapestVariantInStockDkk &&
                  cheapestVariantInStockDkk > variantPriceDk)
              ) {
                cheapestVariantInStock = variant;
                cheapestVariantInStockDkk = variantPriceDk;
              }
            } else if (variant.allow_backorder) {
              if (
                (cheapestVariantWithBackorder === undefined &&
                  cheapestVariantWithBackorderDkk === undefined) ||
                (cheapestVariantWithBackorderDkk &&
                  cheapestVariantWithBackorderDkk > variantPriceDk)
              ) {
                cheapestVariantWithBackorder = variant;
                cheapestVariantWithBackorderDkk = variantPriceDk;
              }
            } else if (variantRestockDate) {
              if (
                earliestVariantRestock === undefined ||
                earliestVariantRestockDate === undefined ||
                (earliestVariantRestockDate &&
                  earliestVariantRestockDate > variantRestockDate)
              ) {
                earliestVariantRestock = variant;
                earliestVariantRestockDate = variantRestockDate;
              }
            } else {
              if (
                (cheapestVariantOutOfStock === undefined &&
                  cheapestVariantOutOfStockDkk === undefined) ||
                (cheapestVariantOutOfStockDkk &&
                  cheapestVariantOutOfStockDkk > variantPriceDk)
              ) {
                cheapestVariantOutOfStock = variant;
                cheapestVariantOutOfStockDkk = variantPriceDk;
              }
            }
          } else {
            console.warn(
              `WARNING: product: ${product.id}, variant: ${variantId} has no valid DKK price`,
            );
          }
        });

      variant =
        cheapestVariantInStock ||
        cheapestVariantWithBackorder ||
        earliestVariantRestock ||
        cheapestVariantOutOfStock;
    }

    return variant;
  };

  public getMPN = (id: Product['id']) => {
    const product = this.data[id];
    if (!product || !product.handle) {
      return null;
    }

    const mpn = product.handle.toLowerCase();

    const brandName = product.collection_id
      ? this.#store.collections.data[product.collection_id].title.toLowerCase()
      : null;

    if (!brandName) return null;

    return mpn.startsWith(brandName + '_')
      ? mpn.slice(brandName.length + 1)
      : mpn;
  };

  public getHighestAvailableStock = (id: Product['id']) => {
    try {
      const product = this.data[id];
      if (product && product.variants) {
        const variants = product.variants.map(
          (variantId) => this.#store.productVariants.data[variantId],
        );
        return variants.reduce((acc, variant) => {
          return acc > variant.inventory_quantity
            ? acc
            : variant.inventory_quantity;
        }, 0);
      }
      return 0;
    } catch (e) {
      console.error(e);
      return 0;
    }
  };

  public getAllowBackorder = (id: Product['id']) => {
    try {
      const product = this.data[id];
      if (product && product.variants) {
        const variants = product.variants.map(
          (variantId) => this.#store.productVariants.data[variantId],
        );
        return variants.reduce((acc, variant) => {
          return acc || variant.allow_backorder;
        }, false);
      }
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  //price of product formatted for screen
  public getPrice = (id: Product['id']) => {
    try {
      const variant = this.getVariant(id);
      if (!variant) return null;
      const price = this.#store.productVariants.getPrice(variant.id);
      return formatPrice(price);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  public getProductThumbnail = (id: Product['id']) => {
    const productsData = this.#store.products.data;
    const product = productsData[id];
    if (!product) return null;
    return product.thumbnail;
  };

  public getProductFromData = (id: Product['id']) => {
    return this.#store.products.data[id];
  };
}
