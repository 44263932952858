import { useEffect, useState } from 'react';
import {
  listenChange,
  type ReturnTuple,
  type StoreSlice,
  useStoreSlice,
} from 'use-change';

const emptyArray: unknown[] = [];

function useMap<STORE, KEY extends keyof SLICE, SLICE = STORE>(
  storeSlice: StoreSlice<STORE, SLICE>,
  keys: KEY[] = emptyArray as KEY[],
): ReturnTuple<SLICE[KEY]>[0][] {
  const slice = useStoreSlice(storeSlice);

  const [stateValue, setStateValue] = useState(keys.map((key) => slice[key]));

  useEffect(() => {
    const handler = () => {
      setStateValue(keys.map((key) => slice[key]));
    };

    handler();

    const unlisten = keys.map((key) => listenChange(slice, key, handler));

    return () => {
      unlisten.map((un) => un());
    };
  }, [keys, slice]);

  return stateValue;
}

export default useMap;
