import type { RootStore } from '.';
import { EntityData, EntityType, MedusaFulfillment } from '@/types/index';

export default class MedusaFulfillments {
  public entityType = EntityType.MEDUSA_FULFILLMENT;

  public data: EntityData<MedusaFulfillment> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }
}
