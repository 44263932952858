import qs from 'qs';
import type { RootStore } from '.';
import {
  Company,
  EntityData,
  EntityType,
  EquipmentItem,
  Office,
  StoreListResponse,
  Team,
  User,
} from '@/types/index';

export default class EquipmentItems {
  public entityType = EntityType.EQUIPMENT_ITEM;

  public loadedEquipmentIds: EquipmentItem['id'][] = [];

  public personalEquipmentIds: EquipmentItem['id'][] = [];

  public currentSelectionTotalCount = 0;

  public canLoadMore = true;

  public data: EntityData<EquipmentItem> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public loadEquipment = async ({
    companyId,
    teamId,
    userId,
    brand,
    assetType,
    reset,
    q,
    officeId,
    orderBy,
    unassigned,
  }: {
    companyId?: Company['id'];
    teamId?: Team['id'];
    userId?: User['id'];
    brand?: string;
    assetType?: string;
    reset: boolean;
    q?: string;
    officeId?: Office['id'];
    unassigned?: boolean;
    orderBy?: 'brand' | 'type' | 'createdAt' | 'assignee' | 'purchasedAt';
  }) => {
    if (reset) {
      this.loadedEquipmentIds = [];
    }

    const limit = 20;
    const offset = this.loadedEquipmentIds.length;

    const response = await this.#store.api<
      StoreListResponse<{ equipmentItems: EquipmentItem['id'][] }>
    >(
      `equipment?${qs.stringify({
        limit,
        offset,
        companyId,
        teamId,
        userId,
        brand,
        assetType,
        q,
        officeId,
        orderBy,
        unassigned,
      })}`,
      { method: 'GET' },
    );

    this.loadedEquipmentIds = [
      ...this.loadedEquipmentIds,
      ...response.equipmentItems,
    ];

    this.currentSelectionTotalCount = response.count;
    this.canLoadMore = this.loadedEquipmentIds.length < response.count;
  };

  public loadPersonalEquipment = async () => {
    const me = this.#store.users.me;
    const myCompanyId = this.#store.companies.myCompany;
    const response = await this.#store.api<
      StoreListResponse<{ equipmentItems: EquipmentItem['id'][] }>
    >(
      `equipment?${qs.stringify({
        type: 'user',
        limit: 1000,
        offset: 0,
        userId: me,
        companyId: myCompanyId,
        orderBy: 'createdAt',
      })}`,
      { method: 'GET' },
    );

    this.personalEquipmentIds = response.equipmentItems;
  };

  public createEquipmentItem = (
    body: Pick<
      EquipmentItem,
      | 'type'
      | 'brand'
      | 'equipmentItemName'
      | 'serialNumber'
      | 'internalId'
      | 'assignedToUserId'
    >,
  ) => {
    return this.#store.api('equipment', {
      method: 'POST',
      body,
      successMessage: 'Successfully created equipment item',
    });
  };

  public loadSingleEquipmentItem = async (id: EquipmentItem['id']) => {
    await this.#store.api<EquipmentItem>(`equipment/${id}`, { method: 'GET' });
  };

  public loadAssetBrands = async ({
    type,
    unassigned,
  }: {
    type?: string;
    unassigned?: boolean;
  }) => {
    return this.#store.api<string[]>(
      `equipment/brands?${qs.stringify({
        type,
        unassigned,
      })}`,
      { method: 'GET' },
    );
  };

  public updateEquipmentItem = (
    id: EquipmentItem['id'],
    body: Partial<EquipmentItem>,
    successMessage?: string,
  ) => {
    return this.#store.api(`equipment/${id}`, {
      method: 'PUT',
      body,
      successMessage: successMessage ?? 'Successfully updated asset',
    });
  };

  public uploadEquipmentCsv = async (file: File, companyId?: Company['id']) => {
    const body = new FormData();

    body.append('file', file);

    return this.#store.api<{ created: number; updated: number }, FormData>(
      `equipment/csv?companyId=${companyId ?? ''}`,
      {
        method: 'POST',
        isFormData: true,
        noServerErrorToast: true,
        body,
      },
    );
  };
}
