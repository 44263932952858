import type { RootStore } from '.';
import { EntityData, EntityType, User } from '@/types/index';
import type { Auth0Role, Role } from '../types/common';

export default class Roles {
  public entityType = EntityType.ROLE;

  public ids: Role['id'][] = [];

  public data: EntityData<Role> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  setUserRole = async (userId: User['id'], auth0Role: Auth0Role) => {
    const existingUser = this.#store.users.data[userId];
    this.#store.users.data[userId] = { ...existingUser, auth0Role };

    await this.#store.api(`roles/set-role-for-user/${userId}`, {
      method: 'PUT',
      body: { role: auth0Role },
      successMessage: "Successfully updated user's role",
      onError: () => {
        this.#store.users.data[userId] = existingUser;
      },
    });
  };

  loadRoles = async () => {
    this.ids = await this.#store.api<Role['id'][]>('roles', { method: 'GET' });
  };
}
