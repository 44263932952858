import type { RootStore } from '.';
import {
  Company,
  EntityData,
  EntityType,
  ReuseStatus,
  UpdateCompany,
  User,
} from '@/types/index';

const industries = [
  'A: Agriculture, hunting, forestry, and fishing',
  'B: Extraction of raw materials',
  'C: Manufacturing',
  'D: Electric, gas, and heat supply',
  'E: Water supply; sewerage, waste management, and remediation activities',
  'F: Construction and civil engineering',
  'G: Wholesale and retail trade; repair of motor vehicles and motorcycles',
  'H: Transportation and storage',
  'I: Hotels and restaurants',
  'J: Information and communication',
  'K: Financial, insurance, real estate, business services, and information services',
  'L: Real estate, rental, and business services',
  'M: Professional, scientific, and technical services',
  'N: Administrative and support services',
  'O: Public administration and defense; compulsory social security',
  'P: Education',
  'Q: Health and social work',
  'R: Arts, entertainment, and recreation',
  'S: Other services',
  'T: Private households with hired domestic staff; production of goods and services by households for own use',
  'U: Extraterritorial organizations and bodies',
];

const sizes = [
  '1 - 19',
  '20 - 49',
  '50 - 99',
  '100 - 249',
  '250 - 499',
  '500 - 999',
  '1,000 - 2,500',
  '2,500+',
];

export default class Companies {
  public entityType = EntityType.COMPANY;

  public ids: Company['id'][] = [];

  public data: EntityData<Company> = {};

  public myCompany: Company['id'] | null = null;

  public approverUserId: User['id'] | null = null;

  public readonly industries = Object.fromEntries(
    industries.map((i) => {
      const [letter, label] = i.split(':');
      return [letter.trim(), label.trim()];
    }),
  );

  public readonly sizes = sizes;

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public createCompany = async (body: Company) => {
    const endpoint = this.#store.users.isSystemAdmin
      ? 'companies'
      : 'companies/customer';
    await this.#store.api(endpoint, {
      method: 'POST',
      body,
      successMessage: 'Successfully created a new company',
    });
  };

  public seedCompany = async (body: {
    companyName: string;
    size: string;
    officeNames: string;
    catalogue: string;
    catalogueRandom: string;
  }) => {
    await this.#store.api('companies/seed', {
      method: 'POST',
      body,
      successMessage: 'Successfully seeded a new company',
    });
  };

  public loadCompanies = async () => {
    this.ids = await this.#store.api('companies', { method: 'GET' });
  };

  public loadSingleCompany = async (id: Company['id']) => {
    await this.#store.api(`companies/${id}`, { method: 'GET' });
  };

  public updateCompany = async (
    id: Company['id'],
    body: UpdateCompany,
    successMessage?: string,
  ) => {
    await this.#store.api(`companies/${id}`, {
      method: 'PUT',
      body,
      successMessage: successMessage || 'Successfully updated the company',
    });
  };

  public updateCompanyLogo = async (id: User['id'], file: File) => {
    const body = new FormData();

    body.append('file', file);

    await this.#store.api(`companies/${id}/logo`, {
      method: 'POST',
      isFormData: true,
      body,
      successMessage: 'Successfully updated the company logo',
    });
  };

  public isDirectorySyncSyncing = (companyId: Company['id']): boolean => {
    const company = this.data[companyId];
    return !!company?.directorySync?.lastSyncedAt;
  };

  public isReuseActive = (companyId: Company['id']): boolean => {
    const company = this.data[companyId];
    return company && company?.reuse !== ReuseStatus.INACTIVE;
  };
}
