import {
  EntityData,
  EntityType,
  EquipmentItem,
  RequestItem,
  RequestItemStatus,
} from '@/types/index';
import { RootStore } from '@/store/index';

export default class RequestItems {
  public entityType = EntityType.REQUEST_ITEM;

  public data: EntityData<RequestItem | null> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public updateRequestItem = async (
    requestItemId: RequestItem['id'],
    status: RequestItemStatus,
    equipmentItemId?: EquipmentItem['id'],
  ) => {
    await this.#store.api(`requests/requestItem/${requestItemId}/update`, {
      method: 'POST',
      body: {
        status,
        equipmentItemId,
      },
      successMessage: 'Successfully updated the request',
    });
  };
}
