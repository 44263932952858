import type { AppProps } from 'next/app';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider as UseChangeProvider } from 'use-change';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwtDecode from 'jwt-decode';

import QuerySelector from '../components/Shared/App/QuerySelector';
import '../styles/globals.css';

import ConfirmModal from '../components/Shared/App/ConfirmModal';
import store from '@/store/index';
import { JWTDecoded } from '../types';
import { useRouter } from 'next/router';

export default function App({ Component, pageProps }: AppProps) {
  const { push } = useRouter();
  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN}
      clientId={process.env.AUTH0_CLIENT_ID}
      issuer={process.env.AUTH0_ISSUER_URL}
      cacheLocation="localstorage"
      useRefreshTokens
      useRefreshTokensFallback
      authorizationParams={{
        redirect_uri:
          typeof window !== 'undefined' ? window.location.origin : undefined,
        audience: process.env.AUTH0_AUDIENCE,
      }}
      onRedirectCallback={async () => {
        const permissions = await store.auth0Data
          ?.getAccessTokenSilently()
          .then((token) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            const { permissions } = jwtDecode(token) as JWTDecoded;
            return permissions;
          });

        if (permissions && permissions.length === 0) {
          push('/user-portal');
        }
      }}
    >
      <UseChangeProvider value={store}>
        <QuerySelector selector="body" className="h-[calc(100%+1px)]" />
        <QuerySelector selector="html" className="h-full" />
        <QuerySelector selector="#__next" className="h-full" />
        <Component {...pageProps} />
        <ToastContainer />
        <ConfirmModal />
      </UseChangeProvider>
    </Auth0Provider>
  );
}
