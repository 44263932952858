import type { RootStore } from '.';
import { EntityData, EntityType, MedusaAddress } from '@/types/index';

export default class MedusaAddresses {
  public entityType = EntityType.MEDUSA_ADDRESS;

  public ids: MedusaAddress['id'][] = [];

  public data: EntityData<MedusaAddress> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }
}
