import {
  Fulfillment,
  Image,
  Address as MedusaAddr,
  Cart as MedusaCart,
  MoneyAmount as MedusaMoneyAmount,
  Order as MedusaOriginalOrder,
  Product as MedusaProduct,
  ProductOption as MedusaProductOption,
  ProductOptionValue as MedusaProductOptionValue,
  ProductType as MedusaProductType,
  ProductVariant as MedusaProductVariant,
  TaxRate as MedusaTaxRate,
  ProductCollection,
  Region,
  ShippingMethod,
  TrackingLink,
} from '@medusajs/client-types';
import {
  Address,
  ArrayElement,
  Auth0Role,
  DirectorySyncProvider,
  JWTDecoded,
  Permission,
  Role,
} from './common';
import { BadgeStatus } from '@/martsUi/Labels/Badge/Badge';

export type Falsy = false | 0 | '' | null | undefined;

export type { Role, JWTDecoded, ArrayElement, Address };
export { Auth0Role, Permission };

export type EntityId = string;

export type EntityData<T> = Record<EntityId, T> & {
  __updatedTimes?: number;
};

export interface Entity {
  id: EntityId;
  entityType: EntityType;
  entityStatus?: EntityStatus;
}

export type Company = Entity & {
  entityType: EntityType.COMPANY;
  createdAt: Date;
  updatedAt: Date;
  companyName: string;
  logo: string | null;
  industry: string;
  phone: string;
  size: string;
  domains: string[];
  orderApproverUserId?: User['id'] | null;
  mdmWindows: boolean;
  reuse: ReuseStatus;
  ticketsEmail: string | null;

  automaticOnboarding: boolean;
  onboardingDeadlineWorkingDays: number;
  automaticOffboarding: boolean;
  onboardingUserSelection?: UserSelection['id'];
  offboardingUserSelection?: UserSelection['id'];

  directorySync?: {
    lastSyncedAt?: Date | null;
    provider?: DirectorySyncProvider | null;
  };

  serviceProvider?: {
    id: string;
    companyName: string;
    logo: string | null;
  } | null;

  customers?: {
    id: string;
    companyName: string;
  }[];

  _count?: {
    customers?: number;
    users?: number;
  };
};

export type UpdateCompany = Partial<
  Omit<Company, 'onboardingUserSelection' | 'offboardingUserSelection'>
> & {
  onboardingUserSelection?: {
    id?: UserSelection['id'];
    rules: UserSelectionRuleUpsert[];
  };
  offboardingUserSelection?: {
    id?: UserSelection['id'];
    rules: UserSelectionRuleUpsert[];
  };
};

export type DirectorySyncGoogleAuthStatus = 'SUCCEEDED' | 'FAILED';

export type DirectorySync = Entity & {
  entityType: EntityType.DIRECTORY_SYNC;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  deletedAt: Date | null;
  endpoint: string;
  token: string;
  provider: DirectorySyncProvider;
  lastRunError: string | null;
  lastSyncedAt: Date | null;
  googleAuthStatus: DirectorySyncGoogleAuthStatus | null;
};

export type User = Entity & {
  entityType: EntityType.USER;
  createdAt: Date;
  updatedAt: Date;
  entityStatus: EntityStatus;
  onboardedAt: Date | null;
  onboardNotificationSentAt: Date | null;
  companyId: string | null;
  officeId: string | null;
  managerId: string | null;
  auth0UserId: string | null;
  email: string;
  firstName: string;
  lastName: string;
  profileImage: string | null;
  auth0Role: Auth0Role;
  startDate: Date | null;
  endDate: Date | null;
  title: string | null;
  locationAddress: string | null;
  locationAddress2: string | null;
  locationCity: string | null;
  locationPostcode: string | null;
  locationCountry: string | null;
  isNewEmployee: boolean;
  teams?: Team['id'][];
  office?: Office['id'];
  medusaCartId: string | null;
  changedStatusForOrders?: Request['id'][];
  teamLeadForOrders?: Request['id'][];
  personalEmail: string | null;
  equipmentSelectionId: string | null;
  onboardingId: string | null;
  medusaOnboardingCartId: string | null;
  _count: {
    equipmentItems: number;
  };
  badges?: { status: BadgeStatus; text: string; tooltip?: string }[];
  syncedLocation: string | null;
  syncedDepartment: string | null;
};

export type UserSelection = Entity & {
  entityType: EntityType.USER_SELECTION;
  createdAt: Date;
  updatedAt: Date;
  rules: UserSelectionRule['id'][];
};

export type UserSelectionRule = Entity & {
  entityType: EntityType.USER_SELECTION_RULE;
  createdAt: Date;
  updatedAt: Date;
  relationship: 'IS_ADMIN' | 'IS_MY_TEAMLEAD' | 'IS_MY_MANAGER' | 'USER';
  whereAttribute?: 'id';
  whereAttributeValue?: User['id'];
};
export type UserSelectionRuleUpsert = Pick<
  UserSelectionRule,
  'relationship' | 'whereAttribute' | 'whereAttributeValue'
>;

export type Office = Entity & {
  entityType: EntityType.OFFICE;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  officeName: string;
  isHeadquarter: boolean;
  locationAddress: string;
  locationAddress2: string | null;
  locationCity: string;
  locationPostcode: string;
  locationCountry: string | null;
  vatId: string | null;
};

export type Team = Entity & {
  entityType: EntityType.TEAM;
  createdAt: Date;
  updatedAt: Date;
  teamLeadUserId: string | null;
  teamName: string;
  deliverySetting: DeliverySetting;
  users?: User['id'][];
  medusaProductIds: string[];
  medusaProductTypeMaxAmountMap?: Record<ProductType['id'], number>;
  companyId: Company['id'];
};

export type Request = Entity & {
  createdAt: Date;
  updatedAt: Date;
  completedAt: Date | null;
  buyerUserId: User['id'];
  statusChangedByUsers?: User['id'][];
  approverTeamLeadUsers?: User['id'][];
  status: RequestStatus;
  medusaCartId: string;
  medusaOrderId: string | null;
  companyId: Company['id'];
  addressType: DeliverySetting; // HOME/OFFICE
  address: Address | undefined;
  totalQuantity: number;
  totalPrice: number;
  shippingStatus?: RequestShipmentStatus;
  requestItems: RequestItem['id'][];
  medusaProducts: Product['id'][];
};

export enum RequestShipmentStatus {
  AWAITING_SHIPMENT = 'AWAITING_SHIPMENT',
  PARTIALLY_SHIPPED = 'PARTIALLY_SHIPPED',
  SHIPPED = 'SHIPPED',
  NOTHING_TO_SHIP = 'NOTHING_TO_SHIP',
}

export type RequestItem = Entity & {
  requestId: Request['id'];
  medusaProductId: Product['id'];
  medusaProductVariantId: ProductVariant['id'];
  quantity: number;
  totalPrice: number;
  mdmWindowsPrice: number | null;
  status: RequestItemStatus;
  reuseEquipmentItemId: EquipmentItem['id'] | null;
  shippedAt?: string;
  trackingNumber: string[];
  suggestions?: {
    type:
      | 'exactAtLocation'
      | 'similarAtLocation'
      | 'categoryInCompany'
      | 'none';
    asset: string | null;
    count: number;
  };
};

export type EquipmentItem = Entity & {
  entityType: EntityType.EQUIPMENT_ITEM;
  createdAt: Date;
  updatedAt: Date;
  purchasedAt: Date | null;
  expiresAt: Date | null;
  warantyExpiresAt: Date | null;
  assignedToUserId: string | null;
  equipmentItemName: string;
  brand: string;
  type: string;
  description: string;
  ean: string | null;
  sku: string | null;
  internalId: string | null;
  status: EquipmentItemStatus;
  price: number;
  imageUrl?: string;
  priceCurrency: string;
  serialNumber: string | null;
  addressType: EquipmentItemAddressType;
  medusaOrderId: MedusaOrder['id'] | null;
  medusaProductId: Product['id'] | null;
  customAddressOfficeId: Office['id'] | null;
  companyId: Company['id'];
};

export type EquipmentLog = Entity & {
  entityType: EntityType.EQUIPMENT_LOG;
  createdAt: Date;
  updatedAt: Date;
  equipmentItemId: string;
  whoUserId: User['id'] | null;
  field: keyof EquipmentItem | null;
  fromValue: string | null;
  toValue: string | null;
  type: EquipmentLogType;
  at: Date;
};

export type EquipmentDocument = Entity & {
  entityType: EntityType.EQUIPMENT_DOCUMENT;
  createdAt: Date;
  updatedAt: Date;
  entityStatus: EntityStatus;
  equipmentItemId: EquipmentItem['id'];
  documentName: string;
  contentType: string;
  s3Key: string;
};

export type Collection = Entity &
  ProductCollection & {
    entityType: EntityType.MEDUSA_COLLECTION;
  };

export type Product = Entity &
  Pick<
    MedusaProduct,
    | 'id'
    | 'title'
    | 'subtitle'
    | 'description'
    | 'thumbnail'
    | 'handle'
    | 'collection_id'
    | 'type_id'
    | 'status'
    | 'metadata'
  > & {
    entityType: EntityType.MEDUSA_PRODUCT;
    variants: ProductVariant['id'][];
    in_stock?: boolean;
    images?: MedusaImage['id'][];
  };

export type ProductType = Entity &
  MedusaProductType & {
    entityType: EntityType.MEDUSA_PRODUCT_TYPE;
  };

export type ProductVariant = Entity &
  Pick<
    MedusaProductVariant,
    | 'id'
    | 'product_id'
    | 'inventory_quantity'
    | 'allow_backorder'
    | 'metadata'
    | 'title'
  > & {
    entityType: EntityType.MEDUSA_PRODUCT_VARIANT;
    prices: MoneyAmount['id'][];
  };

export type MedusaImage = Entity &
  Image & {
    entityType: EntityType.MEDUSA_IMAGE;
  };

export type MoneyAmount = Entity &
  Pick<MedusaMoneyAmount, 'id' | 'currency_code' | 'amount'> & {
    entityType: EntityType.MEDUSA_MONEY_AMOUNT;
  };

export type ProductOption = Entity &
  MedusaProductOption & {
    entityType: EntityType.MEDUSA_PRODUCT_OPTION;
  };

export type ProductOptionValue = Entity &
  MedusaProductOptionValue & {
    entityType: EntityType.MEDUSA_PRODUCT_OPTION_VALUE;
  };

export type TaxRate = Entity &
  MedusaTaxRate & {
    entityType: EntityType.MEDUSA_TAX_RATE;
  };

export type MedusaRegion = Entity &
  Region & {
    entityType: EntityType.MEDUSA_REGION;
  };

//@medusajs/medusa/dist/api/routes/store/carts/create-cart.d.ts
type StorePostCartReqItem = {
  variant_id: string;
  quantity: number;
};

export type LineItem = Entity &
  StorePostCartReqItem & {
    entityType: EntityType.MEDUSA_LINE_ITEM;
    metadata: Record<string, unknown> | null;
    fulfilled_quantity: number | null;
    shipped_quantity: number | null;
  };

export type Cart = Entity &
  Omit<MedusaCart, 'items'> & {
    entityType: EntityType.MEDUSA_CART;
    items: Team['id'][];
    shipping_address: MedusaAddress['id'];
  };

export type MedusaAddress = Entity &
  MedusaAddr & {
    entityType: EntityType.MEDUSA_ADDRESS;
  };

export type ShippingOption = Entity &
  ShippingMethod & {
    entityType: EntityType.MEDUSA_SHIPPING_METHOD;
  };

export type MedusaOrder = Entity &
  MedusaOriginalOrder & {
    entityType: EntityType.MEDUSA_ORDER;
    fulfillments: MedusaFulfillment['id'][];
  };

export type MedusaTrackingLink = Entity &
  TrackingLink & {
    entityType: EntityType.MEDUSA_TLINK;
  };

export type MedusaFulfillment = Entity &
  Fulfillment & {
    entityType: EntityType.MEDUSA_FULFILLMENT;
    tracking_links: MedusaTrackingLink['id'][];
  };

export type Margin = Entity & {
  entityType: EntityType.MARGIN;
  id: string;
  martsServiceProviderId: Company['id'];
  martsCompanyId?: Company['id'] | null;
  marginPercentage: number;
};

export type Article = Entity & {
  entityType: EntityType.NEWS;
  title: string;
  description?: string;
  publishedAt: Date;
  url: string;
  imageUrl?: string;
  category: string;
  brands: string[];
};

export type MarginUpsert = Pick<Margin, 'martsCompanyId' | 'marginPercentage'> &
  Partial<Pick<Margin, 'id' | 'martsServiceProviderId'>>;

export enum EntityType {
  USER = 'USER',
  USER_SELECTION = 'USER_SELECTION',
  USER_SELECTION_RULE = 'USER_SELECTION_RULE',
  ROLE = 'ROLE',
  COMPANY = 'COMPANY',
  DIRECTORY_SYNC = 'DIRECTORY_SYNC',
  OFFICE = 'OFFICE',
  REQUEST = 'REQUEST',
  REQUEST_ITEM = 'REQUEST_ITEM',
  ORDER = 'ORDER',
  TEAM = 'TEAM',
  EQUIPMENT_ITEM = 'EQUIPMENT_ITEM',
  EQUIPMENT_LOG = 'EQUIPMENT_LOG',
  EQUIPMENT_DOCUMENT = 'EQUIPMENT_DOCUMENT',
  MARGIN = 'MARGIN',
  MEDUSA_COLLECTION = 'MEDUSA_COLLECTION',
  MEDUSA_PRODUCT = 'MEDUSA_PRODUCT',
  MEDUSA_PRODUCT_TYPE = 'MEDUSA_PRODUCT_TYPE',
  MEDUSA_PRODUCT_VARIANT = 'MEDUSA_PRODUCT_VARIANT',
  MEDUSA_IMAGE = 'MEDUSA_IMAGE',
  MEDUSA_MONEY_AMOUNT = 'MEDUSA_MONEY_AMOUNT',
  MEDUSA_PRODUCT_OPTION = 'MEDUSA_PRODUCT_OPTION',
  MEDUSA_PRODUCT_OPTION_VALUE = 'MEDUSA_PRODUCT_OPTION_VALUE',
  MEDUSA_TAX_RATE = 'MEDUSA_TAX_RATE',
  MEDUSA_REGION = 'MEDUSA_REGION',
  MEDUSA_LINE_ITEM = 'MEDUSA_LINE_ITEM',
  MEDUSA_CART = 'MEDUSA_CART',
  MEDUSA_ADDRESS = 'MEDUSA_ADDRESS',
  MEDUSA_SHIPPING_METHOD = 'MEDUSA_SHIPPING_METHOD',
  MEDUSA_SHIPPING_OPTION = 'MEDUSA_SHIPPING_OPTION',
  MEDUSA_LITL = 'MEDUSA_LITL', // IDK what's that but it's coming from an order
  MEDUSA_SMTL = 'MEDUSA_SMTL', // IDK what's that but it's coming from an order
  MEDUSA_ORDER = 'MEDUSA_ORDER',
  MEDUSA_CUSTOMER = 'MEDUSA_CUSTOMER',
  MEDUSA_SALES_CHANNEL = 'MEDUSA_SALES_CHANNEL',
  MEDUSA_TLINK = 'MEDUSA_TLINK',
  MEDUSA_FULFILLMENT = 'MEDUSA_FULFILLMENT',
  NEWS = 'NEWS',
}

export enum EntityStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum ReuseStatus {
  INACTIVE = 'INACTIVE',
  SUGGESTED = 'SUGGESTED',
}

export enum DeliverySetting {
  HOME = 'HOME',
  OFFICE = 'OFFICE',
  FLEXIBLE = 'FLEXIBLE',
}

export enum OrderStatus {
  REQUEST = 'REQUEST',
  TEAM_APPROVED = 'TEAM_APPROVED',
  COMPANY_APPROVED = 'COMPANY_APPROVED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
}

export enum RequestStatus {
  REQUEST = 'REQUEST',
  TEAM_APPROVED = 'TEAM_APPROVED',
  COMPANY_APPROVED = 'COMPANY_APPROVED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
}

export enum RequestItemStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REUSE = 'REUSE',
}

export enum EquipmentItemStatus {
  WORKING = 'WORKING',
  RESERVED = 'RESERVED',
  MAINTENANCE = 'MAINTENANCE',
  DECOMMISSIONED = 'DECOMMISSIONED',
}

export enum EquipmentItemAddressType {
  HOME = 'HOME',
  OFFICE = 'OFFICE',
  CUSTOM = 'CUSTOM',
}

export enum EquipmentLogType {
  CREATED = 'CREATED',
  PURCHASED = 'PURCHASED',
  SHIPPED = 'SHIPPED',
  REUSED = 'REUSED',
  FIELD_CHANGED = 'FIELD_CHANGED',
}

export type StoreListResponse<T> = {
  limit: number;
  offset: number;
  count: number;
} & T;

export interface OnboardingCatalogueCategory {
  productTypeId: string;
  allowedQuantity: number | null;
  productIds: Product['id'][];
}

export type OnboardingStep = `ptyp_${string}` | 'WELCOME' | 'CHECKOUT';
