import type { RootStore } from '.';
import { Collection, EntityData, EntityType } from '@/types/index';

export default class Collections {
  public entityType = EntityType.MEDUSA_COLLECTION;

  public ids: Collection['id'][] = [];

  public data: EntityData<Collection> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public loadCollections = async () => {
    this.ids = await this.#store.api('collections', {
      method: 'GET',
      noAuth: true,
    });
  };
}
