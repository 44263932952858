import type { RootStore } from '.';
import { Company, EntityData, EntityType, Office } from '@/types/index';

export default class Offices {
  public entityType = EntityType.OFFICE;

  public officeIds: Office['id'][] = [];

  public data: EntityData<Office> = {};

  public myOffice: Office['id'] | null = null;

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public createOffice = async (
    body: Omit<Office, 'entityType' | 'id' | 'createdAt' | 'updatedAt'>,
  ) => {
    await this.#store.api('offices', {
      method: 'POST',
      body,
      successMessage: 'Successfully created a new location',
    });
  };

  public loadOffices = async (companyId?: Company['id']) => {
    const endpoint = companyId ? `offices?companyId=${companyId}` : 'offices';
    this.officeIds = await this.#store.api(endpoint, {
      method: 'GET',
    });
  };

  public companyOffices = (companyId?: Company['id'] | null) => {
    const offices = Object.values(this.data).filter(
      (office) => typeof office,
    ) as Office[];
    if (!companyId) return offices;
    return offices.filter(
      (office) => office.companyId === companyId,
    ) as Office[];
  };

  public updateOffice = async (id: Office['id'], body: Partial<Office>) => {
    await this.#store.api(`offices/${id}`, {
      method: 'PUT',
      body,
      successMessage: 'Successfully updated the location',
    });
  };

  public deleteOffice = async (id: Office['id']) => {
    await this.#store.api(`offices/${id}`, {
      method: 'DELETE',
      successMessage: 'Successfully deleted the location',
    });
  };
}
