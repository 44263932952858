import { listenChange } from 'use-change';

import type { RootStore } from '.';
import {
  Company,
  EntityData,
  EntityType,
  Permission,
  Product,
  Team,
  User,
} from '@/types/index';
import { uniq } from 'lodash';

export default class Users {
  public entityType = EntityType.USER;

  public me: User['id'] | null = null;

  public onboardingUserId: User['id'] | null = null;

  public canApproveRequests = false;

  public myPermissions: Permission[] | null = null;

  public userIds: User['id'][] = [];

  public data: EntityData<User> = {};

  public isSystemAdmin: boolean | undefined = undefined;

  public isServiceAdmin: boolean | undefined = undefined;

  public isServiceAgent: boolean | undefined = undefined;

  public isOnlyServiceAgent: boolean | undefined = undefined;

  public isCompanyAdmin: boolean | undefined = undefined;

  public isSomeonesTeamLead = false;

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;

    listenChange(this, 'myPermissions', (perms) => {
      this.isSystemAdmin = !!perms?.includes(Permission.SYSTEM_ADMIN);
      this.isServiceAdmin = !!perms?.includes(Permission.SERVICE_ADMIN);
      this.isServiceAgent = !!perms?.includes(Permission.SERVICE_AGENT);
      this.isCompanyAdmin = !!perms?.includes(Permission.COMPANY_ADMIN);
      this.isOnlyServiceAgent = this.isServiceAgent && !this.isServiceAdmin;
    });

    listenChange(
      this.#store.teams.data,
      '__updatedTimes',
      this.#updateSomeonesTeamLead,
    );
    listenChange(this, 'me', this.#updateSomeonesTeamLead);
  }

  public onData = (id: User['id'], data: User, endpoint: string) => {
    // endpoint === 'users/me' is a workaround since this.me is set after the response was parsed
    if (endpoint === 'users/me' || id === this.me) {
      this.#store.companies.myCompany = data.companyId;
      this.#store.offices.myOffice = data.officeId;
      this.#store.carts.myCart = data.medusaCartId;
      this.#store.teams.myTeams = data.teams ?? this.#store.teams.myTeams ?? [];

      const company = data.companyId
        ? this.#store.companies.data[data.companyId]
        : null;
      this.canApproveRequests =
        this.#store.teams.myTeams.some((teamId) => {
          return this.#store.teams.data[teamId].teamLeadUserId === id;
        }) ||
        company?.orderApproverUserId === id ||
        ((this.isCompanyAdmin ?? false) && !this.isOnlyServiceAgent);

      if (company?.orderApproverUserId) {
        this.#store.companies.approverUserId = company?.orderApproverUserId;
      }
    }
  };

  public users = (companyId: Company['id'] | undefined) => {
    const users = this.userIds.map((id) => this.data[id]);

    if (this.isOnlyServiceAgent && users.length !== 0) {
      return users.filter(
        (user) =>
          user.companyId !== this.#store.companies.myCompany &&
          (companyId === undefined || user.companyId === companyId),
      );
    }

    return users.filter(
      (user) => companyId === undefined || user.companyId === companyId,
    );
  };

  public createUser = async (
    body: Partial<User> & {
      password: string;
      notificationType: 'welcome' | 'onboarding' | null;
      shouldUsePersonalEmail: boolean;
    },
  ) => {
    await this.#store.api('users', {
      method: 'POST',
      body,
      successMessage: 'Successfully created a new user',
    });
  };

  public updateUser = async (id: User['id'], body: Partial<User>) => {
    const existingUser = this.data[id];
    this.data[id] = { ...existingUser, ...body };

    await this.#store.api(`users/${id}`, {
      method: 'PUT',
      body,
      successMessage: 'Successfully updated the user',
      onError: () => {
        this.data[id] = existingUser;
      },
    });
  };

  public updateUserAvatar = async (id: User['id'], file: File) => {
    const body = new FormData();

    body.append('file', file);

    await this.#store.api(`users/${id}/avatar`, {
      method: 'POST',
      isFormData: true,
      body,
      successMessage: 'Successfully updated the user avatar',
    });
  };

  public uploadUserCsv = async (file: File, companyId?: Company['id']) => {
    const body = new FormData();

    body.append('file', file);

    return this.#store.api<{ created: number; updated: number }, FormData>(
      `users/csv?companyId=${companyId ?? ''}`,
      {
        method: 'POST',
        isFormData: true,
        noServerErrorToast: true,
        body,
      },
    );
  };

  public loadAllUsers = async (companyId?: Company['id']) => {
    const endpoint = companyId ? `users?companyId=${companyId}` : 'users';
    this.userIds = await this.#store.api(endpoint, { method: 'GET' });
  };

  public loadSingleUser = (id: User['id']) => {
    return this.#store.api(`users/${id}`, { method: 'GET' });
  };

  public deleteUser = async (id: User['id']) => {
    await this.#store.api(`users/${id}`, {
      method: 'DELETE',
      successMessage: 'Successfully deleted the user',
    });

    this.userIds = this.userIds.filter((userId) => userId !== id);
  };

  public loadMe = async () => {
    this.me = await this.#store.api<User['id']>('users/me');
  };

  public loadOnboardingUser = async (onboardingId: string) => {
    this.onboardingUserId = await this.#store.api(
      `onboarding/${onboardingId}`,
      {
        method: 'GET',
        noAuth: true,
        noServerErrorToast: true,
      },
    );
  };

  public sendOnboardingUserEmail = async (
    userId: User['id'],
    personalEmail?: string,
  ) => {
    await this.#store.api(`users/${userId}/onboarding-notification`, {
      method: 'POST',
      body: { personalEmail },
      successMessage: 'Successfully sent the onboarding email',
    });
  };

  public sendOffboardingUserEmail = async (
    userId: User['id'],
    personalEmail?: string,
  ) => {
    await this.#store.api(`users/${userId}/offboarding-notification`, {
      method: 'POST',
      body: { personalEmail },
      successMessage: 'Successfully sent the offboarding email',
    });
  };

  // see https://github.com/circlecell/randomkeygen.com/blob/master/js/index.js
  public generatePassword = () => {
    const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
    const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '1234567890';
    const special = '`~!@#$%^&*()-=_+[]{}|;\':",./<>?';
    const hex = '123456789ABCDEF';

    function random() {
      const { crypto, Uint32Array } = window;
      if (
        typeof crypto?.getRandomValues === 'function' &&
        typeof Uint32Array === 'function'
      ) {
        // Divide a random UInt32 by the maximum value (2^32 -1) to get a result between 0 and 1
        return (
          window.crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295
        );
      }

      return Math.random();
    }

    function keyGen(
      length: number,
      useLowerCase = true,
      useUpperCase = true,
      useNumbers = true,
      useSpecial = true,
      useHex = false,
    ) {
      let chars = '';
      let key = '';

      if (useLowerCase) chars += lowerCase;
      if (useUpperCase) chars += upperCase;
      if (useNumbers) chars += numbers;
      if (useSpecial) chars += special;
      if (useHex) chars += hex;

      for (let i = 0; i < length; i++) {
        key += chars[Math.floor(random() * chars.length)];
      }

      return key;
    }

    return keyGen(30, true, true, true, true, false);
  };

  getTeamLeadIds = (teams?: string[]) => {
    const userTeams = Object.values(this.#store.teams.data).filter((team) => {
      if (typeof team === 'number') return false;
      return (
        (teams || this.#store.teams.myTeams).some((id) => id === team.id) &&
        !!team.teamLeadUserId
      );
    }) as Team[];

    return uniq(userTeams.map(({ teamLeadUserId }) => teamLeadUserId)).filter(
      (id) => id !== null,
    ) as string[];
  };

  newUser = (user: User) => !!user.badges?.find(({ text }) => /New/.test(text));
  onboardingUser = (user: User) =>
    !!user.badges?.find(({ text }) => /Onboarding/.test(text));
  onboardingUserNeedsAttention = (user: User) =>
    !!user.badges?.find(({ text }) => /Onboarding (missed|blocked)/.test(text));
  offboardingUser = (user: User) =>
    !!user.badges?.find(({ text }) => /Offboarding/.test(text));

  isProductIdInTeamCatalogue = async (
    productId: Product['id'],
  ): Promise<boolean> => {
    // Wait for me to be loaded, in order for the teams.data to be available.
    await this.#store.meIsLoaded;
    const teams = this.#store.teams.data;
    const teamCatalogueWithProductId = this.#store.teams.myTeams.find((id) =>
      teams[id].medusaProductIds.includes(productId),
    );
    return teamCatalogueWithProductId !== undefined;
  };

  public meIsLoaded = () => {
    return this.#store.meIsLoaded;
  };

  #updateSomeonesTeamLead = () => {
    this.isSomeonesTeamLead = Object.values(this.#store.teams.data).some(
      (team) => {
        if (typeof team === 'number') return false;
        return team.teamLeadUserId === this.me;
      },
    );
  };
}
