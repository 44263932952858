import { useAuth0 } from '@auth0/auth0-react';
import { listenChange } from 'use-change';
import { getCodes, getName } from 'country-list';

import { isEqual } from 'lodash';

import { Company, Entity, EntityId, JWTDecoded } from '@/types/index';
import Roles from './Roles';
import Users from './Users';
import Companies from './Companies';
import Offices from './Offices';
import EquipmentItems from './EquipmentItems';
import jwtDecode from 'jwt-decode';
import Collections from './Collections';
import Products from './Products';
import ProductTypes from './ProductTypes';
import ProductVariants from './ProductVariants';
import MoneyAmounts from './MoneyAmounts';
import MedusaImages from './MedusaImages';
import Teams from './Teams';
import ProductOptions from './ProductOptions';
import ProductOptionValues from './ProductOptionValues';
import Carts from './Carts';
import TaxRates from './TaxRates';
import MedusaRegions from './MedusaRegions';
import MedusaAddresses from './MedusaAddresses';
import LineItems from './LineItems';
import MedusaOrder from './MedusaOrder';
import MedusaFulfillments from './MedusaFulfillments';
import MedusaTrackingLinks from './MedusaTrackingLinks';
import EquipmentLogs from './EquipmentLogs';
import EquipmentDocuments from './EquipmentDocuments';
import { errorToast, successToast } from '@/martsUi/Components/Toast/Toast';
import DirectorySyncs from './DirectorySyncs';
import Requests from '@/store/Requests';
import RequestItems from '@/store/RequestItems';
import UserSelections from './UserSelections';
import UserSelectionRules from './UserSelectionRules';
import Margins from './Margins';
import News from '@/store/News';

export * from './selectors';

export class RootStore {
  public users: Users;

  public userSelections: UserSelections;

  public userSelectionRules: UserSelectionRules;

  public roles: Roles;

  public requests: Requests;

  public requestItems: RequestItems;

  public companies: Companies;

  public directorySyncs: DirectorySyncs;

  public offices: Offices;

  public equipmentItems: EquipmentItems;

  public equipmentDocuments: EquipmentDocuments;

  public collections: Collections;

  public products: Products;

  public productTypes: ProductTypes;

  public productVariants: ProductVariants;

  public moneyAmounts: MoneyAmounts;

  public margins: Margins;

  public medusaImages: MedusaImages;

  public productOptions: ProductOptions;

  public productOptionValues: ProductOptionValues;

  public teams: Teams;

  public carts: Carts;

  public taxRates: TaxRates;

  public medusaRegions: MedusaRegions;

  public lineItems: LineItems;

  public equipmentLogs: EquipmentLogs;

  public medusaAddress: MedusaAddresses;

  public medusaOrders: MedusaOrder;

  public medusaFulfillments: MedusaFulfillments;

  public medusaTrackingLinks: MedusaTrackingLinks;

  public news: News;

  // defined by the hook
  public auth0Data: ReturnType<typeof useAuth0> | null = null;

  public countries = (() => {
    const data: [string, string][] = [];
    getCodes().forEach((code) => {
      const name = getName(code);
      if (code && name) data.push([code, name]);
    });
    return Object.fromEntries(data);
  })();

  public sortedCountries = (() => {
    const data: { value: string; label: string }[] = [];
    getCodes().forEach((value) => {
      const label = getName(value);
      if (label && value) data.push({ value, label });
    });
    data.sort((a, b) => a.label.localeCompare(b.label));
    return data;
  })();

  public confirmModalTitle = 'Are you sure?';

  public confirmModalText = '';

  public confirmModalButtonText = 'OK';

  public confirmModalIsOpen = false;

  public droppedFiles: File[] = [];
  public successToast = successToast;
  public errorToast = errorToast;

  public meIsLoaded: Promise<void>;

  constructor() {
    this.teams = new Teams(this);
    this.users = new Users(this);
    this.userSelections = new UserSelections(this);
    this.userSelectionRules = new UserSelectionRules(this);
    this.roles = new Roles(this);
    this.requests = new Requests(this);
    this.requestItems = new RequestItems(this);
    this.companies = new Companies(this);
    this.directorySyncs = new DirectorySyncs(this);
    this.offices = new Offices(this);
    this.equipmentItems = new EquipmentItems(this);
    this.equipmentDocuments = new EquipmentDocuments(this);
    this.equipmentLogs = new EquipmentLogs(this);
    this.collections = new Collections(this);
    this.products = new Products(this);
    this.productTypes = new ProductTypes(this);
    this.productVariants = new ProductVariants(this);
    this.medusaImages = new MedusaImages(this);
    this.moneyAmounts = new MoneyAmounts(this);
    this.margins = new Margins(this);
    this.productOptions = new ProductOptions(this);
    this.productOptionValues = new ProductOptionValues(this);
    this.taxRates = new TaxRates(this);
    this.medusaRegions = new MedusaRegions(this);
    this.lineItems = new LineItems(this);
    this.medusaAddress = new MedusaAddresses(this);
    this.carts = new Carts(this);
    this.medusaOrders = new MedusaOrder(this);
    this.medusaFulfillments = new MedusaFulfillments(this);
    this.medusaTrackingLinks = new MedusaTrackingLinks(this);
    this.news = new News(this);
    this.meIsLoaded = this.#init();
  }

  /** @abstract */
  public confirmModalConfirm = () => {};

  /** @abstract */
  public confirmModalCancel = () => {};

  // defined at useJune hook
  public track: (
    eventName: string,
    properties: Record<string, unknown>,
  ) => void = () => {};

  public getAccessToken = () => {
    return new Promise<string>((resolve) => {
      if (this.auth0Data?.isAuthenticated) {
        void this.auth0Data
          .getAccessTokenSilently({
            cacheMode: 'off',
          })
          .then(resolve);
      } else {
        listenChange(this, 'auth0Data', (auth0Data) => {
          if (auth0Data) {
            if (auth0Data.isAuthenticated) {
              void auth0Data.getAccessTokenSilently().then((token) => {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                const { permissions } = jwtDecode(token) as JWTDecoded;
                // make is set if actually changed
                this.users.myPermissions = isEqual(
                  permissions,
                  this.users.myPermissions,
                )
                  ? this.users.myPermissions
                  : permissions;
                resolve(token);
              });
            } // else wait for another attempt to set auth0Data
          } else {
            throw new Error('auth0Data is null');
          }
        });
      }
    });
  };

  public confirm = ({
    confirmButtonText = 'OK',
    title = 'Are you sure?',
    text = '',
  }: {
    confirmButtonText?: string;
    title?: string;
    text?: string;
  } = {}) => {
    this.confirmModalTitle = title;
    this.confirmModalText = text;
    this.confirmModalButtonText = confirmButtonText;
    this.confirmModalIsOpen = true;

    return new Promise<boolean>((resolve) => {
      this.confirmModalConfirm = () => {
        this.confirmModalIsOpen = false;
        resolve(true);
      };

      this.confirmModalCancel = () => {
        this.confirmModalIsOpen = false;
        resolve(false);
      };
    });
  };

  public loadS3SignedUrl = async (s3Key: string) => {
    return this.api<{ url: string }>(`uploads/signed-url?s3Key=${s3Key}`, {
      method: 'GET',
    });
  };

  public loadInsightsIframeUrl = async (companyId: Company['id']) => {
    return this.api<{ url: string }>(
      `insights/iframe-url?companyId=${companyId}`,
      {
        method: 'GET',
      },
    );
  };

  public api = async <RESP, BODY = never>(
    endpoint: string,
    {
      method,
      body,
      successMessage,
      onError,
      isFormData,
      noAuth,
      noServerErrorToast,
      returnNullOnEmpty,
      qs,
    }: {
      method: 'GET' | 'POST' | 'PUT' | 'DELETE';
      body?: BODY;
      successMessage?: string;
      onError?: () => void;
      isFormData?: boolean;
      noAuth?: boolean;
      noServerErrorToast?: boolean;
      returnNullOnEmpty?: boolean;
      qs?: Record<string, string>;
    } = { method: 'GET' },
  ): Promise<RESP> => {
    let token: string | undefined;

    if (!noAuth) {
      token = await this.getAccessToken();

      const { permissions } = jwtDecode<JWTDecoded>(token);

      // make is set if actually changed
      this.users.myPermissions = isEqual(permissions, this.users.myPermissions)
        ? this.users.myPermissions
        : permissions;
    }

    if (!process.env.API_URL) {
      throw new Error('process.env.API_URL is not set');
    }

    const request = await fetch(
      `${process.env.API_URL}${endpoint}${
        qs ? `?${new URLSearchParams(qs)}` : ''
      }`,
      {
        method,
        headers: {
          ...(token ? { Authorization: 'Bearer ' + token } : {}),
          ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
        },
        body: body
          ? isFormData
            ? (body as unknown as FormData)
            : JSON.stringify(body)
          : undefined,
      },
    );

    type Result =
      | Entity
      | Entity[]
      | {
          error?: string;
          message: string;
          statusCode: number;
        };

    let result: Result;
    const blob = await request.blob();
    if (returnNullOnEmpty && blob.size === 0) {
      return null as RESP;
    }
    try {
      result = JSON.parse(await blob.text()) as Result;
    } catch {
      const message = `Failed to parse result of a request made to ${endpoint}`;
      this.errorToast(message);

      onError?.();

      throw new Error(message);
    }

    if (!request.ok && !('error' in result) && !('message' in result)) {
      const message = `Failed to make a request to ${endpoint}`;

      this.errorToast(message);

      onError?.();

      throw new Error(message);
    }

    if (('error' in result || 'message' in result) && !('id' in result)) {
      const message = result.message || result.error || 'Unknown error';

      if (!noServerErrorToast) {
        this.errorToast(message);
      }

      onError?.();
      throw new Error(message);
    } else {
      if (successMessage) {
        this.successToast(successMessage);
      }

      // @ts-expect-error Unknown
      return this.#parseResult(result, endpoint);
    }
  };

  /* public getUploadedImageSrc = async (src: string) => {
    function arrayBufferToBase64(buffer: ArrayBuffer) {
      return btoa(String.fromCharCode(...new Uint8Array(buffer)));
    }

    const headers = new Headers();
    const token = await this.getAccessToken();
    headers.set('Authorization', `Bearer ${token}`);
    const resp = await fetch(src, { headers });

    const binaryData = await resp.arrayBuffer();
    const base64 = arrayBufferToBase64(binaryData);
    const dataUrl = `data:image/png;base64,${base64}`;

    return dataUrl;
  } */

  #parseResult = (
    result: Entity | Entity[],
    endpoint: string,
  ): EntityId | EntityId[] | null | unknown => {
    const entity: Partial<Entity> = {};
    if (Array.isArray(result)) {
      return result.map((item) =>
        this.#parseResult(item, endpoint),
      ) as EntityId[];
    }

    // if primitive return it
    if (typeof result !== 'object' || result === null) {
      return result;
    }

    const { entityType, id } = result;

    for (const [key, value] of Object.entries(result)) {
      /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument */
      entity[key as keyof Entity] =
        typeof value === 'object' && value !== null
          ? this.#parseResult(value, endpoint)
          : value;
      /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument */
    }

    if (!entityType || !id) return entity;

    const branches = [
      'users',
      'userSelections',
      'userSelectionRules',
      'roles',
      'companies',
      'directorySyncs',
      'offices',
      'collections',
      'products',
      'productTypes',
      'productVariants',
      'medusaImages',
      'moneyAmounts',
      'margins',
      'teams',
      'productOptions',
      'productOptionValues',
      'taxRates',
      'medusaRegions',
      'lineItems',
      'medusaAddress',
      'carts',
      'requests',
      'requestItems',
      'medusaOrders',
      'medusaFulfillments',
      'medusaTrackingLinks',
      'equipmentItems',
      'equipmentLogs',
      'equipmentDocuments',
      'news',
    ] as const;

    for (const b of branches) {
      const branch = this[b];

      if (branch.entityType === entityType) {
        if ('onData' in branch) {
          // @ts-expect-error ignore
          branch.onData(id, entity, endpoint);
        }
        branch.data[id] = Object.assign({}, branch.data[id], entity);
        branch.data.__updatedTimes = (branch.data.__updatedTimes ?? 0) + 1;
      }
    }

    return id;
  };

  #init = async () => {
    void this.collections.loadCollections();
    void this.productTypes.loadProductTypes();
    // don't make requests below to be made on onboarding page
    if (typeof window === 'undefined' || window.location.href.includes('/e/'))
      return;
    // load me first to initialise user on back-end then load everything else after
    await this.users.loadMe();
    void this.companies.loadCompanies().then(() => {
      void this.offices.loadOffices();
      void this.teams.loadTeams();
    });
    void this.roles.loadRoles();
    void this.requests.loadMyApproverRequests(); // updates the badge
  };
}

const store = new RootStore();

if (typeof window !== 'undefined') {
  (window as unknown as { store: RootStore }).store = store;
}

export default store;
