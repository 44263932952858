import { twMerge } from 'tailwind-merge';
import alertCircle from './svgs/alertCircle.svg';
import arrowLeft from './svgs/arrowLeft.svg';
import arrowNarrowDown from './svgs/arrowNarrowDown.svg';
import arrowNarrowUpRight from './svgs/arrowNarrowUpRight.svg';
import barChart from './svgs/barChart.svg';
import blank from './svgs/blank.svg';
import box from './svgs/box.svg';
import building from './svgs/building.svg';
import calendar from './svgs/calendar.svg';
import check from './svgs/check.svg';
import checkDone from './svgs/checkDone.svg';
import checkDotted from './svgs/checkDotted.svg';
import chevronDown from './svgs/chevronDown.svg';
import chevronLeft from './svgs/chevronLeft.svg';
import chevronRight from './svgs/chevronRight.svg';
import cloud from './svgs/cloud.svg';
import dotsVertical from './svgs/dotsVertical.svg';
import faceSmile from './svgs/faceSmile.svg';
import file from './svgs/file.svg';
import fileCheck from './svgs/fileCheck.svg';
import homeSmile from './svgs/homeSmile.svg';
import infoCircle from './svgs/infoCircle.svg';
import marts from './svgs/marts.svg';
import menu from './svgs/menu.svg';
import minus from './svgs/minus.svg';
import monitor from './svgs/monitor.svg';
import notificationBox from './svgs/notificationBox.svg';
import plus from './svgs/plus.svg';
import searchSm from './svgs/searchSm.svg';
import settings from './svgs/settings.svg';
import shieldTick from './svgs/shieldTick.svg';
import shoppingCart from './svgs/shoppingCart.svg';
import tag from './svgs/tag.svg';
import truck from './svgs/truck.svg';
import uploadCloud from './svgs/uploadCloud.svg';
import users from './svgs/users.svg';
import xClose from './svgs/xClose.svg';
import refresh from './svgs/refresh.svg';
import stars from './svgs/stars.svg';
import company from './svgs/company.svg';
import linkExternal from './svgs/linkExternal.svg';

const icons = {
  alertCircle,
  arrowLeft,
  arrowNarrowDown,
  arrowNarrowUpRight,
  barChart,
  blank,
  box,
  building,
  calendar,
  check,
  checkDone,
  checkDotted,
  chevronDown,
  chevronLeft,
  chevronRight,
  cloud,
  company,
  dotsVertical,
  faceSmile,
  file,
  fileCheck,
  homeSmile,
  infoCircle,
  marts,
  menu,
  minus,
  monitor,
  notificationBox,
  plus,
  searchSm,
  settings,
  shieldTick,
  shoppingCart,
  tag,
  truck,
  uploadCloud,
  users,
  xClose,
  refresh,
  stars,
  linkExternal,
} as const;

export type IconName = keyof typeof icons;

export const iconNames = Object.keys(icons) as IconName[];

export type IconProps = {
  name: IconName;
  className?: string;
};

const Icon = ({ name, className }: IconProps) => {
  if (!icons[name]) {
    return null;
  }
  const IconComponent = icons[name];

  return (
    <IconComponent className={twMerge(`aspect-square w-6`, className ?? '')} />
  );
};

export default Icon;
