import type { RootStore } from '.';
import { EntityData, EntityType, MedusaOrder } from '@/types/index';

export default class MedusaOrders {
  public entityType = EntityType.MEDUSA_ORDER;

  public ids: MedusaOrder['id'][] = [];

  public data: EntityData<MedusaOrder> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }
}
