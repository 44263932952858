import type { RootStore } from '.';
import { EntityData, EntityType, ProductType } from '@/types/index';

export default class ProductTypes {
  public entityType = EntityType.MEDUSA_PRODUCT_TYPE;

  public ids: ProductType['id'][] = [];

  public data: EntityData<ProductType> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public loadProductTypes = async () => {
    this.ids = await this.#store.api('product-types', {
      method: 'GET',
      noAuth: true,
    });
  };
}
