import { Company, DirectorySync, EntityData, EntityType } from '@/types/index';
import { RootStore } from '@/store/index';
import { DirectorySyncProvider } from '@/types/common';

export default class DirectorySyncs {
  public entityType = EntityType.DIRECTORY_SYNC;

  public data: EntityData<DirectorySync | null> = {};
  public myDirectorySyncId: DirectorySync['id'] | null = null;

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public loadSingleDirectorySync = async (companyId: Company['id']) => {
    this.myDirectorySyncId = await this.#store.api<DirectorySync['id'] | null>(
      `directory-sync/${companyId}`,
      {
        method: 'GET',
        returnNullOnEmpty: true,
      },
    );
  };

  public createDirectorySync = async (
    companyId: Company['id'],
    provider: DirectorySyncProvider,
    googleDomain: string,
  ) => {
    const body = {
      provider,
      googleDomain,
    };
    this.myDirectorySyncId = await this.#store.api<
      DirectorySync['id'],
      { provider: DirectorySync['id'] }
    >(`directory-sync/${companyId}`, {
      method: 'POST',
      body,
    });

    return this.myDirectorySyncId;
  };

  public deleteDirectorySync = async (companyId: Company['id']) => {
    const result = await this.#store.api<{ success: boolean }>(
      `directory-sync/${companyId}`,
      {
        method: 'DELETE',
        successMessage: 'Directory sync connection deleted',
      },
    );
    if (result.success && !!this.myDirectorySyncId) {
      this.data[this.myDirectorySyncId] = null;
      this.myDirectorySyncId = null;
    }
  };
}
