import type { RootStore } from '.';
import { EntityData, EntityType, MedusaTrackingLink } from '@/types/index';

export default class MedusaTrackingLinks {
  public entityType = EntityType.MEDUSA_TLINK;

  public data: EntityData<MedusaTrackingLink> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }
}
