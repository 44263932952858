import type { RootStore } from '.';
import { EntityData, EntityType, UserSelectionRule } from '@/types/index';

export default class UserSelectionRules {
  public entityType = EntityType.USER_SELECTION_RULE;

  public data: EntityData<UserSelectionRule> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }
}
