import useChange, { useValue } from 'use-change';

import { ROOT } from '@/store/selectors';
import Button from '@/martsUi/Buttons/Button/Button';
import Modal from '@/martsUi/Components/Modal/Modal';

const ConfirmModal = () => {
  const confirmModalTitle = useValue(ROOT, 'confirmModalTitle');
  const confirmModalText = useValue(ROOT, 'confirmModalText');
  const confirmModalButtonText = useValue(ROOT, 'confirmModalButtonText');
  const confirmModalIsOpen = useValue(ROOT, 'confirmModalIsOpen');
  const [confirmModalConfirm] = useChange(ROOT, 'confirmModalConfirm');
  const confirmModalCancel = useValue(ROOT, 'confirmModalCancel');

  return (
    <Modal
      title={confirmModalTitle}
      isOpen={confirmModalIsOpen}
      onRequestClose={confirmModalCancel}
      buttons={
        <div className="text-right flex-1">
          <Button
            color="secondary"
            className="mr-2"
            onClick={confirmModalCancel}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={confirmModalConfirm}>
            {confirmModalButtonText}
          </Button>
        </div>
      }
    >
      {confirmModalText}
    </Modal>
  );
};

export default ConfirmModal;
