/*
    This is the common types that are shared between marts-front-end and marts-api.
    After you modify the file copy and paste its contents to the corresponding repository's file that's located in the same-named folder.
*/

export enum Permission {
  SYSTEM_ADMIN = 'admin:superadmin',
  SERVICE_ADMIN = 'admin:serviceadmin',
  SERVICE_AGENT = 'admin:serviceagent',
  COMPANY_ADMIN = 'admin:company',
}

export interface Role {
  id: string;
  name: string;
  description: string;
  entityType?: 'ROLE'; // assign entityType to simulate DB entry
}

export interface JWTDecoded {
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  permissions: Permission[];
  email: string; // custom field defined by a rule
}

export const Auth0Role = {
  SYSTEM_ADMIN: process.env.AUTH0_ROLE_SYSTEM_ADMIN,
  SERVICE_ADMIN: process.env.AUTH0_ROLE_SERVICE_ADMIN,
  SERVICE_AGENT: process.env.AUTH0_ROLE_SERVICE_AGENT,
  COMPANY_ADMIN: process.env.AUTH0_ROLE_COMPANY_ADMIN,
  EMPLOYEE: process.env.AUTH0_ROLE_EMPLOYEE,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare, no-redeclare
export type Auth0Role = (typeof Auth0Role)[keyof typeof Auth0Role];

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type Address = {
  locationAddress: string | null;
  locationAddress2: string | null;
  locationCity: string | null;
  locationPostcode: string | null;
  locationCountry: string | null;
};

export enum DirectorySyncProvider {
  'azure-scim-v2' = 'Azure SCIM v2.0',
  'onelogin-scim-v2' = 'OneLogin SCIM v2.0',
  'okta-scim-v2' = 'Okta SCIM v2.0',
  'jumpcloud-scim-v2' = 'JumpCloud v2.0',
  'generic-scim-v2' = 'Generic SCIM v2.0',
  'google' = 'Google',
}

export const directorySyncProviderNames: Record<string, string> = {
  'azure-scim-v2': 'Azure',
  'onelogin-scim-v2': 'OneLogin',
  'okta-scim-v2': 'Okta',
  'jumpcloud-scim-v2': 'JumpCloud',
  'generic-scim-v2': 'SCIM',
  google: 'Google',
} as const;

export const directorySyncProviderHowtos: Record<string, string> = {
  'azure-scim-v2':
    'https://help.marts.io/en/articles/8990007-configuring-microsoft-entra-id-scim',
  'onelogin-scim-v2':
    'https://boxyhq.com/docs/directory-sync/providers/onelogin',
  'okta-scim-v2': 'https://boxyhq.com/docs/directory-sync/providers/okta',
  'jumpcloud-scim-v2':
    'https://boxyhq.com/docs/directory-sync/providers/jumpcloud',
  'generic-scim-v2': '',
  google: 'https://boxyhq.com/docs/directory-sync/providers/google',
};
