import type { RootStore } from '.';
import { EntityData, EntityType, ProductOption } from '@/types/index';

export default class ProductOptions {
  public entityType = EntityType.MEDUSA_PRODUCT_OPTION;

  public ids: ProductOption['id'][] = [];

  public data: EntityData<ProductOption> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }
}
