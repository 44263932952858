import { Article, EntityData, EntityType } from '@/types/index';
import type { RootStore } from '@/store/index';

export default class News {
  public entityType = EntityType.NEWS;

  public initialized = false;

  public newsIds: Article['id'][] = [];

  public data: EntityData<Article | null> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public loadNews = async (category?: string) => {
    const endpoint =
      category && category !== 'all' ? `news?category=${category}` : `news`;
    this.newsIds = await this.#store.api(endpoint, { method: 'GET' });
    this.initialized = true;
  };
}
