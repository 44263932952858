import type { RootStore } from '.';
import { EntityData, EntityType, Margin, MarginUpsert } from '@/types/index';

export default class Margins {
  public entityType = EntityType.MARGIN;

  public ids: Margin['id'][] = [];

  public data: EntityData<Margin> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public loadMargins = async () => {
    this.ids = await this.#store.api<Margin['id'][]>(`margins`, {
      method: 'GET',
    });
  };

  public upsertMargin = async (body: MarginUpsert, successMessage?: string) => {
    return await this.#store.api(`margins`, {
      method: 'POST',
      body,
      successMessage,
    });
  };

  public deleteMargin = async (
    marginId: Margin['id'],
    successMessage?: string,
  ) => {
    return await this.#store.api(`margins/${marginId}`, {
      method: 'DELETE',
      successMessage,
    });
  };
}
