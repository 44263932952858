import { errorToast } from '@/martsUi/Components/Toast/Toast';
import type { RootStore } from '.';
import {
  Address,
  Cart,
  EntityData,
  EntityType,
  LineItem,
  Product,
  ProductVariant,
  User,
} from '@/types/index';

export default class Carts {
  public entityType = EntityType.MEDUSA_CART;

  public myCart: Cart['id'] | null = null;

  public data: EntityData<Cart> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public loadMyCart = async () => {
    // get companyId from query string
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as string),
    }) as { companyId?: string };
    const { companyId } = params;

    if (!companyId) {
      errorToast('Company id is missing.');
      return;
    }

    this.myCart = await this.#store.api('cart/my-cart', {
      body: { companyId },
      method: 'POST',
    });
  };

  public getVariantIdIfProductIdInCart = (
    productId: Product['id'],
    cartId?: Cart['id'],
  ) => {
    let cart: Cart | null = null;
    if (cartId) {
      cart = this.data[cartId];
    } else if (this.myCart) {
      cart = this.data[this.myCart];
    }
    if (!cart) return null;
    const items = cart.items.map((id) => this.#store.lineItems.data[id]) ?? [];
    const item = items.find(
      ({ variant_id }) =>
        this.#store.productVariants.data[variant_id].product_id === productId,
    );
    if (!item) return null;
    return item?.variant_id;
  };

  public modifyItem = async (
    cartId: Cart['id'],
    productId: Product['id'],
    quantity: number,
    onboardingId?: User['onboardingId'],
  ) => {
    interface lineUpdateProps {
      cartId: Cart['id'];
      variantId: ProductVariant['id'];
      quantity: number;
      itemId?: LineItem['id'];
    }
    let bodies: lineUpdateProps[] = [];

    const product = this.#store.products.data[productId];

    const cart = this.#store.carts.data[cartId];
    const items = cart?.items.map((id) => this.#store.lineItems.data[id]) ?? [];
    if (!product.variants) {
      // This is a fallback for when a product in the cart is an old product without variants
      // just reset the cart completely, this is a hack
      bodies = items.map((item) => ({
        cartId,
        variantId: item.variant_id,
        quantity: 0,
        itemId: item.id,
      }));
    } else {
      let item = items.find(
        ({ variant_id }) =>
          product.variants?.some((variantId) => variant_id === variantId),
      );

      const variant = this.findCheapestVariant(product, quantity);
      if (!variant) return this.#store.errorToast('Not enough stock');

      if (item) {
        if (item.variant_id !== variant.id) {
          // old variant in cart is not cheapeast with right stock, remove old variant
          bodies.push({
            cartId,
            variantId: item.variant_id,
            quantity: 0,
            itemId: item.id,
          });
          item = undefined; //we are next creating a new lineitem
        }
      }
      const quantityZeroAndNoItem = quantity === 0 && !item?.id;
      if (!quantityZeroAndNoItem) {
        bodies.push({
          cartId,
          variantId: variant.id,
          quantity,
          itemId: item?.id,
        });
      }
    }
    if (onboardingId) {
      await this.#store.api(`onboarding/${onboardingId}/modify-items`, {
        method: 'POST',
        body: bodies,
        noAuth: true,
      });
    } else {
      await this.#store.api('cart/modify-items', {
        method: 'POST',
        body: bodies,
      });
    }
  };

  public modifyOnboardingItem = async (
    onboardingId: User['onboardingId'],
    productId: Product['id'],
    quantity: number,
  ) => {
    if (!onboardingId) {
      return this.#store.errorToast('Onboarding ID is missing');
    }

    const user = Object.values(this.#store.users.data).find(
      (u) => typeof u !== 'number' && u.onboardingId === onboardingId,
    ) as User | undefined;

    if (!user) {
      return this.#store.errorToast('Unable to find user by onboarding ID');
    }

    const cart = user.medusaOnboardingCartId
      ? this.#store.carts.data[user.medusaOnboardingCartId]
      : null;

    if (!cart) {
      return this.#store.errorToast('User has no onboarding cart');
    }
    await this.modifyItem(cart.id, productId, quantity, onboardingId);
  };

  public cartHasProduct = (cartId: Cart['id'], productId: Product['id']) => {
    const cart = this.data[cartId];
    const product = this.#store.products.data[productId];
    if (product.status === 'draft') return false;
    const items = cart?.items.map((id) => this.#store.lineItems.data[id]) ?? [];
    return items.some(
      ({ variant_id }) =>
        product.variants?.some((variantId) => variant_id === variantId),
    );
  };

  public getDeliveryAddress = (cartId: Cart['id']) => {
    const cart = this.data[cartId];
    const cartAddress = this.#store.medusaAddress.data[cart.shipping_address];

    const deliveryAddress: Address = {
      locationAddress: cartAddress?.address_1,
      locationAddress2: cartAddress?.address_2,
      locationCity: cartAddress?.city,
      locationPostcode: cartAddress?.postal_code,
      locationCountry: cartAddress?.country_code?.toUpperCase() ?? 'dk',
    };

    return deliveryAddress;
  };

  // Find the cheapest variant with right quantity
  private findCheapestVariant = (product: Product, quantity: number) => {
    // find cheapest variant in stock
    let variant = product.variants
      .map((variantId) => this.#store.productVariants.data[variantId])
      .filter((variant) => variant.inventory_quantity >= quantity)
      .sort(
        (a, b) =>
          this.#store.productVariants.getPrice(b.id) -
          this.#store.productVariants.getPrice(a.id),
      )
      .pop();
    // if no cheapest variant in stock, try to find cheapest variant that allows backorders
    variant =
      variant ||
      product.variants
        .map((variantId) => this.#store.productVariants.data[variantId])
        .filter((variant) => variant.allow_backorder)
        .sort(
          (a, b) =>
            this.#store.productVariants.getPrice(b.id) -
            this.#store.productVariants.getPrice(a.id),
        )
        .pop();
    return variant;
  };
}
