import type { RootStore } from '.';
import { EntityData, EntityType, MoneyAmount } from '@/types/index';

export default class MoneyAmounts {
  public entityType = EntityType.MEDUSA_MONEY_AMOUNT;

  public ids: MoneyAmount['id'][] = [];

  public data: EntityData<MoneyAmount> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }
}
