import type { RootStore } from '.';
import {
  EntityData,
  EntityType,
  MoneyAmount,
  ProductVariant,
} from '@/types/index';

export default class ProductVariants {
  public entityType = EntityType.MEDUSA_PRODUCT_VARIANT;

  public ids: ProductVariant['id'][] = [];

  public data: EntityData<ProductVariant> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  // variant price as a number in cents
  public getPrice = (id: ProductVariant['id']) => {
    const variant: { id: string; prices: MoneyAmount['id'][] } =
      this.#store.productVariants.data[id];

    const moneyAmountId = variant.prices?.find((id) => {
      const price = this.#store.moneyAmounts.data[id];
      if (price.currency_code === 'dkk') return true;
    });
    if (!moneyAmountId)
      throw new Error(
        `no dkk  money amount found for variantId: ${
          variant.id
        }\n${JSON.stringify(variant)}`,
      );
    return this.#store.moneyAmounts.data[moneyAmountId].amount;
  };

  public getRestockDate = (id: ProductVariant['id']): Date | null => {
    const variant = this.#store.productVariants.data[id];
    return variant.metadata?.WarehouseDeliveryEta
      ? new Date(variant.metadata.WarehouseDeliveryEta)
      : null;
  };
}
