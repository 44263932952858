import type { RootStore } from '.';
import {
  EntityData,
  EntityType,
  EquipmentItem,
  EquipmentLog,
} from '@/types/index';

export default class EquipmentLogs {
  public entityType = EntityType.EQUIPMENT_LOG;

  public ids: EquipmentLog['id'][] = [];

  public data: EntityData<EquipmentLog> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }

  public loadEquipmentLogs = async (equipmentItemId: EquipmentItem['id']) => {
    this.ids = await this.#store.api(`equipment-logs/${equipmentItemId}`, {
      method: 'GET',
    });
  };
}
