import type { RootStore } from '.';
import { EntityData, EntityType, TaxRate } from '@/types/index';

export default class TaxRates {
  public entityType = EntityType.MEDUSA_TAX_RATE;

  public ids: TaxRate['id'][] = [];

  public data: EntityData<TaxRate> = {};

  #store: RootStore;

  constructor(store: RootStore) {
    this.#store = store;
  }
}
